import Prism from "prism-react-renderer/prism";
// import rootWrapper from './gatsby-wrapper'

(typeof global !== "undefined" ? global : window).Prism = Prism;

require("prismjs/components/prism-django");
require("prismjs/components/prism-dart");
require("prismjs/components/prism-powershell");
require("prismjs/components/prism-uri");
require("prismjs/components/prism-http");
require("prismjs/components/prism-kotlin");



// export const wrapRootElement = rootWrapper
