exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-archive-js": () => import("./../../../src/pages/blog/archive.js" /* webpackChunkName: "component---src-pages-blog-archive-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-blog-posts-artificial-intelligence-the-future-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/buildhome/repo/src/blog/posts/artificial-intelligence-the-future.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-blog-posts-artificial-intelligence-the-future-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-blog-posts-build-a-linear-regression-model-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/buildhome/repo/src/blog/posts/build-a-linear-regression-model.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-blog-posts-build-a-linear-regression-model-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-blog-posts-hello-world-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/buildhome/repo/src/blog/posts/hello-world.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-blog-posts-hello-world-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-blog-posts-practical-kiss-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/buildhome/repo/src/blog/posts/practical-kiss.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-blog-posts-practical-kiss-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-blog-posts-top-10-backend-frameworks-in-2022-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/buildhome/repo/src/blog/posts/top-10-backend-frameworks-in-2022.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-blog-posts-top-10-backend-frameworks-in-2022-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-blog-posts-web-accessibility-designing-for-all-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/buildhome/repo/src/blog/posts/web-accessibility-designing-for-all.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-blog-posts-web-accessibility-designing-for-all-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-blog-posts-why-use-sass-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/buildhome/repo/src/blog/posts/why-use-sass.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-blog-posts-why-use-sass-mdx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

